<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col
            xs="12"
            md="10"
          />
          <b-col
            xs="12"
            md="2"
          >
            <b-button
              to="/hr/notifications/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(title)="data">
              <div class="font-weight-bold">
                {{ data.item.title }}
              </div>
              <small class="text-primary">{{ data.item.type }}</small>
            </template>
            <template #cell(status)="data">
              <div class="text-center">
                <b-badge
                  v-if="data.item.status === '1'"
                  variant="success"
                >
                  Tamamlandı
                </b-badge>
                <b-badge
                  v-else
                  variant="warning"
                >
                  Taslak
                </b-badge>
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                text="İşlemler"
                variant="primary"
                size="sm"
              >
                <b-dropdown-item :to="'/hr/notifications/view/' + data.item.id">
                  Görüntüle
                </b-dropdown-item>
                <b-dropdown-item
                  :to="'/hr/notifications/edit/' + data.item.id"
                  :disabled="data.item.status === '1'"
                >
                  Güncelle
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="deleteData(data.item.id)">
                  Sil
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'DURUM',
          thStyle: { width: '200px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '50px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_hr_notifications.id AS id',
          'com_hr_notifications.title AS title',
          'com_hr_notifications.status AS status',
          'com_hr_notification_types.title AS type',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['hrNotifications/getHr_notifications']
      return data
    },
    dataCount() {
      return this.$store.getters['hrNotifications/getHr_notificationsCount']
    },
    deletedStatus() {
      return this.$store.getters['hrNotifications/getHr_notificationDeleteStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    deletedStatus(val) {
      if (val.status) {
        if (val.status === true) {
          const config = {
            icon: 'success',
            title: 'İşlem Başarılı!',
            text: val.message,
            html: null,
            buttonText: 'Kapat',
          }
          this.sweetAlert(config)
        } else if (val.status === false) {
          const config = {
            icon: 'error',
            title: 'İşlem Hatası!',
            text: val.message,
            html: null,
            buttonText: 'Kapat',
          }
          this.sweetAlert(config)
        }
        this.submitStatus = false
        this.getDataList()
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('hrNotifications/hr_notificationsList', this.dataQuery)
    },
    deleteData(id) {
      this.$store.dispatch('hrNotifications/hr_notificationDelete', id)
    },
  },
}
</script>
<style scoped></style>
